import isVariableDefinedNotNull from './is_variable_defined_not_null';
import isArray from './is_array';

function isObject(value) {
  if (!isVariableDefinedNotNull(value)) return false;

  if (isArray(value)) return false;

  return typeof value === 'object';
}

export default isObject;
