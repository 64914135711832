import getData from '../data/get';
import setData from '../data/set';
import toElementsArray from '../element/to_elements_array';
import isVariableDefinedNotNull from './is_variable_defined_not_null';

function removeListenerById(elements, { id, handler = null, listener = null }, useCapture) {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementListeners = getData(element, 'listeners');

    if (isVariableDefinedNotNull(elementListeners)) {
      const listenerData = elementListeners[id];

      if (!listenerData) continue;

      if (!isVariableDefinedNotNull(handler) || handler === listenerData.originalHandler) {
        listenerData.types = (listenerData.types || []).filter((l) => {
          if (isVariableDefinedNotNull(listener) && l !== listener) {
            return true;
          }

          element.removeEventListener(l, listenerData.slHandler, useCapture);

          return false;
        });
      }

      if (listenerData.types.length === 0) {
        delete elementListeners[id];
      }

      setData(element, 'listeners', elementListeners);
    }
  }

  return true;
}

function removeListenerByHandler(elements, { handler, listener = null }, useCapture) {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementListeners = getData(element, 'listeners');

    if (isVariableDefinedNotNull(elementListeners)) {
      const listenerIds = Object.keys(elementListeners).filter(
        (id) => elementListeners[id].originalHandler === handler,
      );

      listenerIds.forEach((listenerId) => {
        const listenerData = elementListeners[listenerId];

        listenerData.types = (listenerData?.types || []).filter((l) => {
          if (isVariableDefinedNotNull(listener) && l !== listener) {
            return true;
          }

          element.removeEventListener(l, listenerData.slHandler, useCapture);

          return false;
        });

        if (listenerData.types.length === 0) {
          delete elementListeners[listenerId];
        }
      });

      setData(element, 'listeners', elementListeners);
    }
  }

  return true;
}

function removeListenerByListenerType(elements, { listener }, useCapture) {
  const listenersToRemove = listener.split(' ').filter((val) => val.trim() !== '');

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementListeners = getData(element, 'listeners');

    if (isVariableDefinedNotNull(elementListeners)) {
      const listenerIds = Object.keys(elementListeners);

      listenerIds.forEach((listenerId) => {
        const listenerData = elementListeners[listenerId];

        listenerData.types = (listenerData?.types || []).filter((l) => {
          if (!listenersToRemove.includes(l)) {
            return true;
          }

          element.removeEventListener(l, listenerData.slHandler, useCapture);

          return false;
        });

        if (listenerData.types.length === 0) {
          delete elementListeners[listenerId];
        }
      });

      setData(element, 'listeners', elementListeners);
    }
  }

  return true;
}

function removeListener(target, toRemove = { id: null, handler: null, listener: null }, useCapture = true) {
  const elements = toElementsArray(target);

  if (elements.length === 0) {
    return false;
  }

  if (isVariableDefinedNotNull(toRemove.id)) {
    removeListenerById(elements, toRemove, useCapture);
    return true;
  }

  if (isVariableDefinedNotNull(toRemove.handler)) {
    removeListenerByHandler(elements, toRemove, useCapture);
    return true;
  }

  if (isVariableDefinedNotNull(toRemove.listener)) {
    removeListenerByListenerType(elements, toRemove, useCapture);
    return true;
  }

  return false;
}

export default removeListener;
