const DOMAINS = ['slideslive.com', 'slideslive.cz', 'slideslive.de', 'slideslive.at', 'slideslive.ch'];
const DEV_DOMAINS = DOMAINS.map((domain) => `${domain}.test`).concat(['slideslive-test.dev']);

function compareHostname(domain, hostname) {
  return hostname === domain || hostname.endsWith(`.${domain}`);
}

function slidesLiveDomain({ defaultDomain = 'slideslive.com', allowDevDomains = true } = {}) {
  const hostname = window.location.hostname.toLowerCase();
  for (const domain of DOMAINS) {
    if (compareHostname(domain, hostname)) {
      return domain;
    }
  }

  if (allowDevDomains) {
    for (const domain of DEV_DOMAINS) {
      if (compareHostname(domain, hostname)) {
        return domain;
      }
    }
  }

  return defaultDomain;
}

export default slidesLiveDomain;
