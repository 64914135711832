import { Application } from '@hotwired/stimulus';

import { registerControllers } from './vite_helpers';

export default class StimulusIntegration {
  static application() {
    if (!window.Stimulus) {
      window.Stimulus = Application.start(document.documentElement);
    }

    return window.Stimulus;
  }

  static registerContext(controllerModules, options = {}) {
    registerControllers(this.application(), controllerModules, options);
  }

  static register(identifier, controller) {
    this.application().register(identifier, controller);
  }
}
