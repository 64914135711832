import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import isArray from 'plugins/utilities/is_array';
import isObject from 'plugins/utilities/is_object';

function createParamName(previousName, name) {
  if (previousName) {
    return `${previousName}[${encodeURIComponent(name)}]`;
  }

  return encodeURIComponent(name);
}

function toParamsQueryString(params, { previousName = null, originalParams = null } = {}) {
  const r = paramsStrings => {
    const paramsString = isArray(paramsStrings) ? paramsStrings.join('&') : paramsStrings;

    if (originalParams) {
      return [originalParams, paramsString].join('&');
    }

    return paramsString;
  };

  if (isArray(params)) {
    return r(params.map(value => toParamsQueryString(value, { previousName: `${previousName}[]` })));
  }

  if (isObject(params)) {
    return r(
      Object.keys(params)
        .map(name => {
          const paramName = createParamName(previousName, name);
          const paramsValue = params[name];
          return toParamsQueryString(paramsValue, { previousName: paramName });
        })
        .filter(paramString => !!paramString),
    );
  }

  if (typeof params === 'boolean') {
    return r(`${previousName}=${encodeURIComponent(params ? 'true' : 'false')}`);
  }

  if (typeof params === 'string' || typeof params === 'number') {
    return r(`${previousName}=${encodeURIComponent(params || '')}`);
  }

  if (isVariableDefinedNotNull(params)) {
    console.warn('toParamsQueryString: Unknown value type', typeof params, params);
  }

  return null;
}

export default toParamsQueryString;
