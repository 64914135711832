function remove(element) {
  if (!element) {
    return false;
  }

  if (Element.prototype.remove) {
    return element.remove();
  }

  if (!element.parentNode) {
    return false;
  }

  return element.parentNode.removeChild(element);
}

export default remove;
