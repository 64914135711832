import getData from 'plugins/data/get';
import setData from 'plugins/data/set';
import toElementsArray from 'plugins/element/to_elements_array';
import isObject from 'plugins/utilities/is_object';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

let passiveSupported = null;

try {
  const options = {
    get passive() {
      // This function will be called when the browser attempts to access the passive property.
      passiveSupported = true;
      return false;
    },
  };

  window.addEventListener('test', null, options);
  window.removeEventListener('test', null, options);
} catch (err) {
  passiveSupported = false;
}

function addListener(target, listener, ...args) {
  const elements = toElementsArray(target);

  if (elements.length === 0) {
    return false;
  }

  const listeners = listener.split(' ').filter((val) => val.trim() !== '');
  const uniqueId = Math.random().toString(36).substr(2);

  let selector = null;
  let handler = null;
  let options = true;

  for (let i = 0; i < args.length; i++) {
    if (typeof args[i] === 'string') {
      selector = args[i];
    } else if (typeof args[i] === 'function') {
      handler = args[i];
    } else if (typeof args[i] === 'boolean' || isObject(args[i])) {
      options = args[i];
    } else {
      console.warn('Unknown parameter for addListener function:', listener, args[i]);
    }
  }

  if (!isVariableDefinedNotNull(handler)) {
    console.error('Handler must be specified:', listener);
    return false;
  }

  const slHandler = (event) => {
    const evt = event;

    if (selector) {
      const selectorTarget = evt.target.closest(selector);

      if (!selectorTarget || !evt.currentTarget.contains(selectorTarget)) {
        return false;
      }

      evt.selectorTarget = selectorTarget;
    }

    return handler(evt);
  };

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementListeners = getData(element, 'listeners', () => ({})) || {};

    for (const l of listeners) {
      if (passiveSupported) {
        if (!isObject(options)) {
          if (options === true) {
            options = { capture: true };
          } else {
            if (options) {
              console.warn('Unknown options in addListener:', options);
            }

            options = {};
          }
        }

        element.addEventListener(l, slHandler, options);
      } else {
        const useCapture = options === true || (isObject(options) && options.capture);
        element.addEventListener(l, slHandler, useCapture);
      }
    }

    elementListeners[uniqueId] = {
      originalHandler: handler,
      slHandler,
      types: listeners,
    };

    setData(element, 'listeners', elementListeners);
  }

  return uniqueId;
}

export default addListener;
