import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function parseParamName(paramName) {
  const match = paramName.match(/([^[]+)(\[([^\]]+)])?(\[])?/);
  const name = match[1];
  const subName = match[3];
  const isArray = !!match[4];

  return {
    name,
    subName,
    isArray,
  };
}

function urlParam(paramName = '') {
  const url = new URL(window.location);
  const parsedParams = {};

  for (const [key, value] of url.searchParams.entries()) {
    const { name, subName, isArray } = parseParamName(key);

    if (subName) {
      if (!parsedParams[name]) {
        parsedParams[name] = {};
      }

      if (isArray) {
        if (!parsedParams[name][subName]) {
          parsedParams[name][subName] = [];
        }

        parsedParams[name][subName].push(value);
      } else {
        parsedParams[name][subName] = value;
      }
    } else if (isArray) {
      if (!parsedParams[name]) {
        parsedParams[name] = [];
      }

      parsedParams[name].push(value);
    } else {
      parsedParams[name] = value;
    }
  }

  if (!isVariableDefinedNotNull(paramName) || paramName === '') {
    return parsedParams;
  }

  return parsedParams[paramName];
}

export default urlParam;
