import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function setData(object, key, value) {
  if (!isVariableDefinedNotNull(object.__sl)) {
    object.__sl = {};
  }

  if (value === undefined) {
    delete object.__sl[key];
  } else {
    object.__sl[key] = value;
  }

  return object;
}

export default setData;
