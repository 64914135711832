import isArray from 'plugins/utilities/is_array';

function toElementsArray(object) {
  if (!object) return [];

  let o = object;

  if (isArray(o)) return o;

  if (typeof o === 'string') {
    o = document.querySelectorAll(o);

    if (!o || o.length === 0) return [];
  }

  if (o instanceof NodeList || o instanceof HTMLCollection) return [...o];

  return [o];
}

export default toElementsArray;
