import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function getData(object, key, def = null) {
  if (isVariableDefinedNotNull(object?.__sl?.[key])) {
    return object.__sl[key];
  }

  if (def) {
    return def();
  }

  return null;
}

export default getData;
