export const CONTROLLER_FILENAME_REGEX =
  /^(?:.*?(?:controllers|components)\/|(?:\.+\/)+)?(.*?)(?:\/javascript)?\/(.+)[/_-](?:controller|component)\.(?:js|ts)$/;

export function identifierForGlobKey(
  key,
  { withoutNamespace = key.match(/app\/frontend\/(?:components|controllers)/) } = {},
) {
  const match = key.match(CONTROLLER_FILENAME_REGEX);

  if (!match) return null;

  let logicalName = `${match[1]}/${match[2]}`;
  let parts = logicalName.split('/');

  if (withoutNamespace) {
    logicalName = parts[parts.length - 1];
  } else if (
    parts.length > 1 &&
    parts[parts.length - 2].match(new RegExp(`^${parts[parts.length - 1]}[_-](component|controller)$`))
  ) {
    parts.splice(-2, 1);
    logicalName = parts.join('/');
  } else if (parts.length > 1 && parts[parts.length - 2].match(/[_-](component|controller)$/)) {
    parts[parts.length - 2] = parts[parts.length - 2].replace(/[_-](component|controller)$/, '');
    logicalName = parts.join('/');
  } else {
    const appIndex = parts.indexOf('app');
    const isInFrontend = appIndex >= 0 && parts[appIndex + 1] === 'frontend';

    if (isInFrontend) {
      parts = parts.slice(appIndex + 2);

      const javascriptIndex = parts.indexOf('javascript');

      if (javascriptIndex >= 0) {
        parts.splice(javascriptIndex, 1);
      }

      logicalName = parts.join('/');
    }
  }

  return logicalName.replace(/_/g, '-').replace(/\//g, '--');
}

function definitionFromEntry([name, controllerModule], options = {}) {
  const identifier = identifierForGlobKey(name, options);

  if (!identifier) return null;

  const controllerConstructor = controllerModule.default ?? controllerModule;

  if (!identifier || !(typeof controllerConstructor === 'function')) return null;

  return { identifier, controllerConstructor };
}

export function definitionsFromGlob(controllerModules, options = {}) {
  return Object.entries(controllerModules)
    .map((entry) => definitionFromEntry(entry, options))
    .filter((value) => value);
}

export function registerControllers(application, controllerModules, options = {}) {
  application.load(definitionsFromGlob(controllerModules, options));
}
